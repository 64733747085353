@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

$base-color: #ffa223;
$text-color: #060c26;

.policy-head{
	font-size: 40px;
	font-weight: 600;
	font-family: Montserrat-Regular;
	color: #ffa223;
	text-align: center;
	display: flex;
	justify-content:center;
	align-items:center;
	height: 150px;
}

.policy-cont {
  background-color: white;
}

.policy-body {
  display: flex;
  flex-direction: column;
}
.scroll-btn {
  margin: 10px;
  width: 40px;
  height: 40px;
  background-color: transparent;
  border: $base-color solid 2px;
  color: $base-color;
  border-radius: 50%;
}
.sidebar {
  width: 95%;
  display: flex;
  margin: 10px auto;
  ::-webkit-scrollbar {
    display: none;
  }
}

// ::-webkit-scrollbar {
//     display: none;
// }

.card {
  min-width: 300px;
  margin: 0 10px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
  border-bottom: 2px solid $text-color;
  padding: 4px;
  background-color: white;
  border-radius: 0px;
}
.card-active {
  border-bottom: 2px solid $base-color;
  color: $base-color;
}
.card-cont {
  display: flex;
  width: 100%;
  overflow: scroll;
  transition: all 2s ease-in-out;
}
.content {
  width: 97%;
 margin: 0 auto;
  color: $text-color;
  font-family: "Poppins", sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased !important;
  -moz-font-smoothing: antialiased !important;
  text-rendering: optimizelegibility !important;
  letter-spacing: 0.03em;
  line-height: 26px;
  flex: 2;
}

.update-date {
  display: flex;
  justify-content: space-around;
  width: 50%;
  font-size: 1.1rem;
  margin: 20px auto;
  color: $text-color;
  & span {
    font-weight: bold;
    color: $base-color;
  }
}

#left{
	visibility: hidden;
}

@media screen and (max-width: 1224px) {
.update-date{
	width: 100%;
	margin: 10px auto ;
	justify-content: space-evenly;
	flex-direction: column;
	text-align: center;
	& span{
		display: none;
	}
}

.policy-head{
	font-size: 2rem;
}


}


@media screen and (max-width: 960px) {
	.policy-cont {
		height: 25vh;
	  }
  .sidebar {
    display: none;
  }
  .date {
    margin-top: 60px;
  }
  .content {
    margin: 5px;
  }
}

.policy-div {
  margin: 20px;
}
.content b {
  font-size: 22px;
}
