@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@1,900&display=swap');


.NavbarItems{
	height: 80px;
	background-color: transparent;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1.2rem;
	margin-top: 1.5rem;
}

.NavbarItems a{
	text-decoration: none;
}



.nav-logo{
	display: flex;
	justify-content: center;
	align-items: center;
}

.logo-icon{
	color: rgb(226, 224, 224);
	font-weight: bold;
	justify-self: start;
	margin-left: 20px;
	font-family: 'Amaranth', sans-serif;
	letter-spacing: 2px;
	font-weight: 900;
	cursor: pointer;
}

.nav-menu{
	display: grid;
	grid-template-columns: repeat(5,auto);
	grid-gap: 10px;
	list-style: none;
	text-align: center;
	width: 70vw;
	justify-content: end;
	margin-right: 2rem;
}

.nav-link{
	color:white;
	text-decoration: none;
	padding: 0.5rem 0.5rem;
	font-weight: bold;
	margin-right: 50px;
	font-family: 'Montserrat', sans-serif;
	margin-top: 10px;
	height: 50px;
}



.nav-link:hover{
	color: black;
}

.fa-bars{
	color: white;
}

.menu-icon
{
	display: none;
}

.curr{
	color: black;
}
.app-img{
	width: 500px;
	object-fit: contain;
}

.nav-white{
	color: black !important;
}



@media screen and (max-width:1224px) {
	.nav-social{
		& a img{
			width: 30px;
		}
	}
	.nav-logo{
		width: 100%;
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}
	.NavbarItems{
		position: relative;
		margin-top: 0;
	}

	.nav-menu{
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 100%;
		height: 93vh;
		position: absolute;
		top: 80px;
		left: -100%;
		opacity: 1;
		transition: all 0.5s ease;
		padding-top: 20px;
	}
	.nav-menu.active{
		background-color: white;
		left: 0;
		margin: 0 !important;
		opacity: 1;
		transition: all 0.5s ease;
		z-index: 1;
	}
	.nav-link{
		color: black;
		font-size: 1rem;
		& svg{
			margin-right: 20px;
		}

		padding: 1rem;
		width: 100%;
		display: flex;
		min-width: 200px;
		align-items: center;
	}
	.nav-link:hover{
		border-radius: 0;
	}
	.menu-icon{
		display: block;
		position: absolute;
		top: 50%;
		right: 0;
		margin-right: 10px;
		transform: translate(-20%,-50%);
		font-size: 2.4rem;
		cursor: pointer;
	}
	.fa-times{
		color: white;
		font-size: 2.4rem;
		margin-right: 5px;
	}

	.darknav{
		background-color: black;
		z-index: 20;

		& .nav-menu.active{
			background-color: black;
		}
		& .nav-link{
			color: white;
			& svg path{
				fill: white !important;
			}
		}
		& .curr{
			color: #ffa223;
			& svg path{
				fill: #ffa223 !important;
			}
		}
	}
	.curr{
		color: #ffa223;
		& svg path{
			fill: #ffa223 !important;
		}
	}
	.nav-white{
		color: white !important;
	}

	.nav-legal{
		& a{
			font-size: 0.8rem;
			color: black;
		}
	}
	.nav-legal-dark{
		margin-left: 40px;
		color: #dbdee7;
		& a{
			font-size: 0.8rem;
			color: #dbdee7;
		}
	}
	
}