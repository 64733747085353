@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap");
$base-color: #ffa223;

.about-bg {
  position: absolute;
  right: -1%;
  top: 0;
  z-index: -1;
  width: 65%;
}

.about-det {
  width: 38%;
  margin-top: 6em;
  margin-left: 4rem;
  font-family: "Montserrat", sans-serif;
  font-size: 27.5px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: 0.61px;
  text-align: left;
  color: #23233c;

  & h1 {
    font-size: 4rem;
    font-weight: 700;
    letter-spacing: 1.2px;
    color: $base-color;
  }

  & p {
    color: #060c26;
    line-height: 1.57;
    letter-spacing: 0.46px;
    font-size: 1.6rem;
  }
}

.about-card {
  display: flex;
  align-items: center;

  & img {
    width: 35%;
  }
}

.about-info {
  & h1 {
    font-weight: bold;
  }
  & p {
    width: 80%;
    font-size: 1.5rem;
    color: #000000;
  }
}

@media (max-width: 1777px) {
  .about-det p {
    font-size: 1.35rem;
  }
}

@media (max-width:1224px) {
	.about-bg-mb{
		width: 100%;
	}
	.about-det {
		margin: 0;
		width: 90%;
		transform: translateY(-70px);
		margin: 20px;
	
		& h1{
			font-size: 2.5rem;
			font-weight: bold;
		}
		& p {
			font-size: 1.5rem;
		}
	  }
	.about-wrap{
		&>h1{
			position: absolute;
			top: 19%;
			left: 7%;
			font-weight: bold;
			font-size: 2rem;
			z-index: 1;
		}
	}

	.about-card{
		flex-direction: column;
		margin: 0 !important;
		& img{
			width: 100%;
		}
	}
	.about-info{
		text-align: center;
		& p{
			font-size: 2rem;
			width: 95%;
			margin: 10px;
		}
	}
}

@media (max-width:768px) {

	.about-info{
		& p{
			font-size: 1rem;
		}
	}
	.about-det {
		& h1{
			font-size: 1.8rem;
		}
		& p {
			font-size: 1.2rem;
		}
	  }
	  .about-wrap{
		&>h1{
			font-size: 1.4rem;
		}
	}
}