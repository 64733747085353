.ven_bg{
	margin-top: 150px;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 70vh;
}

.ven_bgimg{
	background: linear-gradient(to right, #ff510041, #070707ed), url('img8.jpg');
	z-index: -1;
	background-size: cover;
	background-position-y: center;
	width: 100%;
	height: 80vh;
	position: absolute;
	top: 12%;
}

.ven_block{
	display: flex;
	width: 100%;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	flex-direction: row;
}

.ven_require{
	background-color: white;
	width: 75%;
	padding: 20px;
	border-radius: 10px;
}
.ven_require ul{
	margin-top: 20px;
}
.ven_require ul li i{
	color: rgb(59, 180, 59);
	margin-right: 10px;
}
.ven_require h1{
	font-family: poppins;
	font-weight: bold;
}
.ven_require ul li{
	list-style: none;
}

.ven_headDetail{
	color: white;
	align-self: flex-end;
}
.ven_headDetail h1{
	font-size: 30px;
	font-family: poppins;
	font-weight: bold;
}

.ven_headDetail p{
	letter-spacing: 2px;
	font-size: 50px;
	color: rgb(214, 213, 213);
	width: 80%;
}

@media screen and (max-width: 960px) {
	.ven_headDetail p{
		font-size: 25px;
	}
	.ven_bg{
		margin-top: 100px;
	}
	.ven_require{
		width: 100%;
		margin-top: 80px;
	}
	.ven_bgimg{
		top: 15%;
	}
}