@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');
$base-color: #ffa223;

body{
	overflow-x: hidden;
	margin: 0;
	padding: 0;
	background-color: #fff;
}

/* Section1  */

.home-det{
	margin-top:8em; 
	margin-left: 2rem;
	font-family: 'Montserrat', sans-serif;
	font-size: 27.5px;
	font-weight: 300;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.36;
	letter-spacing: 0.61px;
	text-align: left;
	color: #0d0d14;

	& h1{
		font-size: 4rem;
		font-weight: 600;
		letter-spacing: 1.2px;
		color: $base-color;
	}

	& h4{
		font-size: 30px;
		font-size: 3.5rem;
		letter-spacing: 1.2px;
		margin-bottom: 1.5em;
	}
}

.home-side-img{
	margin-top: 10%;
	transform: translateX(-40px);

	& img{
		width: 350px;
	}
}

.home-bg{
	position: absolute;
	right: -1%;
	top: 0;
	z-index: -1;
	margin: 0px;
}

.home-card{
	width: 70%;
	margin: 0 auto;
	height: 150px;
	display: flex;
	position: relative;
	justify-content: center;
	align-items: center;
	flex-direction: column;
  border-radius: 7.5px;
  box-shadow: 2px 5px 7.5px 0 rgba(0, 0, 0, 0.15);
  background-color: #fff;
  z-index: -1;
}

.outer-card{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	position: relative;
	height: 350px;

	& img{
		position: absolute;
		top: 0%;
		left: 0; 
		right: 0; 
		margin-left: auto; 
		margin-right: auto; 
		width: 120px;
	}
}


.review-card{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	position: relative;
	height: 350px;
	text-align: center;
	color: grey;
	transition: all 1s ease-in-out;

	& img{
		position: absolute;
		top: 0%;
		left: 0; 
		right: 0; 
		margin-left: auto; 
		margin-right: auto; 
		width: 90px;
	}

	&:hover .review{
		border-radius: 5px;
		box-shadow: 5px 5px 22.5px 0 rgba(0, 0, 0, 0.1);
		color: #000000;
	}

	&:hover img{
		border: 3px solid $base-color;
		border-radius: 50%;
	}
}



.review{
	background-color: #fff;
	height: 240px;
	border-radius: 20px;
	width: 90%;
	margin: 0 auto;
	display: flex;
	position: relative;
	justify-content: center;
	align-items: center;
	flex-direction: column;
  	z-index: -1;
	padding: 0px 30px;
	padding-top: 50px;
	transition: all 0.3s ease-in-out;

	& p{
		font-size: 1.3rem;
	}
}



.home-card i{
	font-size: 80px;
	color: $base-color;
	margin-bottom: 40px;
}

.slides2{
	margin:0 40px;
}


.app-img{
	margin-left: 15px;
	margin-top: 10px;
	width: 170px;
}
.slider-cards{
	margin-top: 20px;
	margin-bottom: 150px;
}

/* SEC 3 */

.homesec-3
{
	display: flex;
	align-items: center;
}

.sec3_wrap{
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	justify-content: center;
	align-items: center;
}

.sec3_info{
	width: 40%;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	flex-direction: column;
	margin-bottom: 30px;
	font-size: 20px;

	& h2{
		font-weight: bold;
		font-size: 1.5rem;
		margin-top: 20px;
	}
}


.burger-img{
	height: 30px;
	transform: translateX(50px);
	position: relative;

	& img{
		position: absolute;
		width: 350px;
		right: 0;
	}
}


@media (max-width:1777px) {
	.home-bg{
		width: 60%;
	}

	.home-det{
		& h1{
			font-size: 3.2rem;
		}

		& h4{
			font-size: 2.7rem;
		}
	}


	.homesec-3>img{
		width: 35%;
	}
	.sec3_info{
		width: 45%;
		min-height: 250px;
		padding: 10px;
	}
	.home-side-img img,.burger-img img{
		width: 20%;
	}
	.review p{
		font-size: 1.3rem;
	}
	.home-card{
		width: 80% ;
	}
	
}

.slick-dots li.slick-active button:before, .slick-dots li:focus button:before, .slick-dots li:hover button:before {
    color: $base-color !important;
}

.slick-prev:before,
.slick-next:before {
    font-family:initial!important;
    color:black;
}

.slick-dots li button:before {
    font-size: 14px!important;
    color: #ddd!important;
    opacity: 1!important;
    cursor: pointer;
	margin-top: 15px;
}
.slick-prev:before {
	content: "<";
	color: $base-color;
	font-weight: bold;
	font-size: 40px;
  }
  
  .slick-next:before {
	content: ">";
	font-weight: bold;
	color: $base-color;
	font-size: 40px;
  }


//	 Responsive Mobile   

@media (max-width : 1224px) {
	.home-det{
		margin-top: 200px;
		& h1{
			font-size: 1.9rem;
		}
		& h4{
			font-size: 1.7rem;
		}
	}
	.home-side-img{
		margin-top: 20%;
		transform: scaleX(-1);
		overflow: hidden;
		position: relative;
		height: 100px;
		& img{
			position: absolute;
			left: 0;
			width: 150px;
			transform: translateX(-30px);
		}
	}
	.homesec-3{
		flex-direction: column;

		& > img{
			width: 100%;
		}
	}

	.sec3_wrap{
		flex-direction: column;
	}

	.sec3_info{
		width: 100%;

		& img{
			width: 70px;
		}

		& p br{
			display: none;
		}
	}


}

@media (max-width:500px) {
	.home-bg-mb{
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		width: 100%;
	}

	.home-det{
		margin-top: 0px;
		margin-left: 1.5rem;
		& h1{
			font-size: 1.9rem;
			color: white;
		}
	
		& h4{
			font-size: 20px;
			font-size: 1.7rem;
			margin-bottom: 30px;
		}
	}

	.store-icons-wrapper{
		& img{
			width: 150px;
			margin-bottom: 30rem;
		}
	}

	.home-side-img{
		margin-top: 20%;
		transform: scaleX(-1);
		overflow: hidden;
		position: relative;
		height: 100px;
		& img{
			position: absolute;
			left: 0;
			width: 150px;
			transform: translateX(-30px);
		}
	}

	.outer-card{
		height: 250px;
		& img{
			width: 80px;
		}
	}

	.homesec-3{
		flex-direction: column;

		& > img{
			width: 100%;
		}
	}

	.sec3_wrap{
		flex-direction: column;
	}

	.sec3_info{
		width: 100%;

		& img{
			width: 70px;
		}

		& p br{
			display: none;
		}
	}

	.burger-img{
		height: 50px;
		transform: scaleX(-1);
		position: relative;
	
		& img{
			width: 190px;
			transform: translateX(40px);
		}
	}

	.slides2{
		width: 100%;
		margin: 0px;
	}

	.review p{
		font-size: 1rem;
		width: 100%;
	}
	.review{
		border-radius: 20px;
		box-shadow: 5px 5px 22.5px 0 rgba(0, 0, 0, 0.1);
		color: #000000;
	}

	.review-card{

		& img{
			border: 3px solid $base-color;
			border-radius: 50%;
		}
	}
	
}