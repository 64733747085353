.cp-head{
	color: black;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 15vh;
	text-align: center;
	width: 100%;
}

.cancel-body{
	width: 85%;
	margin: 0 auto;
}
.list-head{
	font-size: 30px;
	margin-left: 15px;
	margin-top: 40px;
}

.list-body{
	margin-left: 30px;
	font-size: 15px;
	white-space: pre-line;
}

@media screen and (max-width:960px) {
	.cancel-body{
		width: 90%;
		margin: 10px 20px;
		font-size: 15px;
	}
}