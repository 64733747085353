.login{
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	height: 30vh;
}

.loginnav{
	display: flex;
	justify-content: center;
	align-self: center;
	padding: 20px;
}

.login img{
	width: 40%;
	margin-bottom: 40px;
}

.login input{
	width: 50%;
	margin: 30px 0px;
}

.btn-orange{
	color: white;
	background-color: rgb(255, 115, 0);
}

.regheader{
	width: 100%;
	background-color:rgb(255, 115, 0);
	color: white;
	text-align: center;
	padding: 10px;
	font-weight: bold;
	margin-bottom: 50px;
}

.regnav{
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 10px 50px;
}
.reginp{
	width: 100%;
}

.form-label{
	font-size: 23px;
}

.reginp input,select{
	margin: 20px 0px;
	width: 50%;
}

.regupload{
	font-size: 25px;
	margin:0px 30px;
}

.regupload input{
	width: 50%;
	margin: 20px 0px;
}

.uploadedAlert{
	background-color: rgb(200, 236, 200);
	width: 500px;
	padding: 10px;
	font-size: 25px;
	margin: 20px 0px;
}
.steps{
	display: flex;
	justify-content: center;
	justify-content: space-between;
	max-width: 1000px;
	margin: 20px;
	margin: 0 auto;
	padding: 20px;
}

.steps div h1{
	color: white;
	padding: 20px;
	width: 50px;
	height: 50px;
	text-align: center;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.steps div h4{
	font-size: 20px;
}

.stepactive{
	background-color:rgb(255, 115, 0);
}
.stepunactive{
	background-color:rgb(104, 103, 102);
}

.steps div{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}


@media (max-width:960px){
	.regupload input,.reginp input,select{
		width: 100%;
	}
	.regnav{
		margin: 15px;
	}
	.regupload{
		margin:10px;
	}
	.login input{
		width: 90%;
	}
	.uploadedAlert{
		width: 300px;
	}
	.steps div h4{
		font-size: 12px;
	}
	
}

