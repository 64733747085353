.faq-body{
	margin: 20px 50px;
}
.faq-body h1{
	text-align: center;
}

.accordion-button{
	background-color:#e7eaf2 !important;
	color: black !important;
	box-shadow: none !important;
	padding: 20px !important;
	font-size: 1.3rem;
	margin: 30px 0px;
}

.accordion-body{
	background-color: white;
}

@media screen and (max-width:960px) {
	.faq-body{
		margin: 20px 10px;
	}
	
}