@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
.footer{
	width: 100%;
	background-color: rgb(253, 253, 253);
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	padding-top: 70px;
	padding-left: 30px;
	padding-right: 30px;
}

.about{
	display: flex;
	flex-direction: column;
	align-self: center;
}

.about img{
	width: 200px;
	margin-bottom: 20px;
}

.legal{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	margin: 0px 40px;
	width: fit-content;
	
}
.legal a{
	margin: 7px 0px;
}
.fwrapper{
	display: flex;
	width: 100%;
	justify-content: space-evenly;
	align-items: flex-start;
	padding: 40px;
}

.social-icons{
	width: 100%;
}

.social-icons img{
	width: 35px;
	margin: 12px;
	display: inline-block;
	transition: transform .2s ease-in-out;
}
.social-icons img:hover{
	transform: scale(1.5);
}
.footer div{
	color: #4c566a;
}
.footer div a{
	text-decoration: none;
	font-size: 15px;
	color: 	#4c566a;
}

@media screen and (max-width:960px) {
	.footer div a{
		font-size: 12px;
	}
	.con-details{
		font-size: 11px;
	}
	.social-icons{
		text-align: center;
	}
	.social-icons img{
		width: 40px;
		margin: 10px;
	}
	.fwrapper{
		flex-direction: column;
	}
	.legal{
		margin: 0;
		margin-top: 20px;
	}
	.footer{
		padding: 0;
	}
	.about p{
		text-align: center;
	}
	.about > img {
		margin: 20px auto;
	}
}