$base-color: #ffa223;

.contact-info {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 600px;
}

.form-wrap {
  display: flex;
  justify-content: space-evenly;
}


.contact-form {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 500px;
  padding: 30px;
  background-color: white;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.233);
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  & .formbg1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    opacity: 0.1;
    filter: grayscale(1);
    z-index: 3;
  }
  & .formbg2 {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    opacity: 0.1;
    filter: grayscale(1);
    z-index: 3;
  }

  .error_form{
	margin: 0px;
	align-self: stretch;
	color: red;
  }



  & input,
  textarea {
    margin: 20px 0px;
    border: none;
    border-bottom: 1px solid;
    outline: none;
    padding: 5px;
    width: 100%;
    font-size: 1.3rem;
    background-color: transparent;
    cursor: pointer;
    z-index: 10;
  }
}

.error_input{
	
	border-bottom: 2px solid red !important;
}

.error_input_phone{
	& input{
		border-bottom: 2px solid red !important;
	}
}
.contact-btn{
	background-image: linear-gradient(to top, #ffcd43, #ffa225);
    height: 50px;
    width: 250px;
    color: white;
    font-weight: bold;
    border: none;
    font-size: 1.3rem;
    border-radius: 50px;
    margin-top: 20px;
    z-index: 10;
}

.contact-side {
  width: 100%;
  position: relative;
  height: 200px;
  & img {
    position: absolute;
    top: -280px;
    width: 600px;
    left: -140px;
    z-index: -1;
  }
}

.success-bg {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #7070706c;
  z-index: 90;
  visibility: hidden;
}

.tactive {
  visibility: visible;
}

.toast-card {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.335);
  width: fit-content;
  position: absolute;
  height: 90px;
  top: 8%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  border-left: 8px solid#46cd4b;
  border-radius: 10px;
  z-index: 100;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  & img {
    margin: 0 20px;
  }

  & div h3 {
    color: #46cd4b;
    font-weight: bold;
  }
}

.nav-item {
  margin: 2px;
  & button {
    width: 100%;
    font-size: 1.3rem;
    font-weight: 500;
    color: black !important;
    background-color: #dadde4 !important;
  }

  & .active {
    border: none !important;

    &.nav-link {
      background-color: $base-color !important;
      color: white !important;
    }
  }
}

.nav-tabs {
  border-bottom: 0px !important;
}

.faq-wrapper {
  margin: 40px 50px;
  width: 70%;
  margin: 0 auto;
}

.accordion-button {
  border-radius: 5px !important;
}

.accordion-button:not(.collapsed) {
  background-color: #ffe1b0 !important;
}

//	 Responsive Mobile

@media (max-width: 1224px) {
	.accordion-button{
		font-size: 1.1rem !important;
	}
  .contact-wrap {
    & > h1 {
      position: absolute;
      top: 19%;
      left: 7%;
      font-weight: bold;
      font-size: 1.5rem;
      z-index: 1;
    }
  }
  .form-wrap {
    flex-direction: column;
  }
  .contact-info {
    width: 95%;
    margin: 10px;
    height: 600px;
  }
  .contact-form {
    width: 100%;
    margin: 0;
    margin-bottom: 100px;
  }


  .contact-wrap {
    & .about-bg {
      width: 57%;
      z-index: -3;
    }
  }

  .faq-wrapper {
    margin: 10px;
    width: 95%;
  }
  .nav-tabs {
    width: 100%;
    overflow-x: auto !important;
    display: flex !important;
    flex-wrap: nowrap !important;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .nav-tabs::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .nav-tabs {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .nav-item {
    & button {
      width: 100% !important;
      margin: 0px !important;
      border: 2px solid red;
	  font-size: 1.1rem !important;
	  justify-content: center;
    }
  }


  .toast-card {
    height: 140px;
    & img {
      width: 40px;
    }

    & div {
      width: 100%;
    }
    & div p {
      font-size: 0.9rem;
      width: 100%;
    }

    & div h3 {
      font-size: 1.4rem;
    }
    & button {
      margin: 0 !important;
    }
  }
}
